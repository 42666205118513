import './App.css';
//import React from 'react';
import React, { useState, useEffect } from 'react';
import Navbar from "./components/Navbar";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages";
import Main from "./pages/main";
import Landing from "./pages/landing";
import About from "./pages/about";
import Blogs from "./pages/register.js";
import SignUp from "./pages/signup";
import Contact from "./pages/contact";
//Global Variables  

//Global Variables END
//components load
//map imports
//import MapView from './components/mapComponent.jsx'; // The component created above
import Map from './components/mapComponent.jsx'; // Adjust the import path based on your file structure
//END map imports


import Header from './components/Header.js';
import LoginScreen from './components/loginScreen.js';
import ImageButton from './components/ImageButton.js'
import NfyTextSeparator from './components/Separator.js'

import reactImg from './assets/login/icon-mobile.png';
import reactImg2 from './assets/login/icon-google.png';
import reactImg3 from './assets/login/icon-fb.png';
import appLogo from './assets/login/gari-logo.png';
import userIcon from './assets/user/user-login.png';
import pinIcon1 from './assets/user/userAvatar1.png';
import pinIcon2 from './assets/user/userAvatar2.png';
import pinIcon3 from './assets/user/userAvatar3.png';
import userPin from './assets/user/map-pin.svg';
import itemsElementMapPin from './assets/icons/item-map-icon.svg';
import itemsElementInfo from './assets/icons/itme-information-icon.svg';
import itemsIconTools from './assets/icons/tools.png';
import itemsIconMusic from './assets/icons/music.png';
import itemsIconIt from './assets/icons/it.png';
import { clear } from '@testing-library/user-event/dist/clear.js';

import userSettingsIconSettings from './assets/user/settings-icon-settings.svg';
import userSettingsIconMap from './assets/user/settings-icon-map.svg';
import userSettingsIconClose from './assets/user/settings-icon-close.svg';
import userSettingsIconStar from './assets/user/settings-icon-star.svg';

import chatIconSend from './assets/user/iconChatSend.svg';

//BASE Elements
  function NfyDevelopMap() {
    return (
      <div></div>
    );
  };

  
  function App() {
    return (
      <div id="pageContainer">
        <Router>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Landing/>} />
                <Route path="/main" element={<Main />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/sign-up" element={<SignUp />} />
            </Routes>
        </Router>
      </div>
    );
  }

export default App;
