import React from "react";
import Header from '../components/Header.js';
import { useNavigate } from "react-router-dom";


 
const Home = () => {

    let navigate = useNavigate(); 
    const goToPageMain = () =>{ 
      let path = `main`; 
      navigate(path);
    }

    return (
        <div id='n4y-LoginScreen' className="n4y-placeholder">
        <div id='initialScreen' className="main active">
          <Header />
          <div className="login-separator">
            <div className="hr"></div>
            <p>Sign up or Login with</p>
          </div>
          <div id="btn1" className="btn Phone">
            <div className="media-icon"></div>
            <label>REGISTER</label>
          </div>
          <div className="login-separator">
          <div className="hr"></div>
            <p>or</p>
          </div>
          <div className="login-placeholder">
            <input type="text"  placeholder="Enter Username" name="uname" required></input>
            <input type="password" placeholder="Enter Password" name="psw" required></input>
            <button type="submit" onClick={goToPageMain}>LOGIN</button>
          </div>
          <div className="login-separator">
            <div className="hr"></div>
            <p>Need an account? <a href="#">Register</a></p>
          </div>
        </div>
      </div>
    );
};
 
export default Home;