import React from "react";
 
const SignUp = () => {
    return (
        <div>
            <h1>Sign Up Successful</h1>
        </div>
    );
};
 
export default SignUp;