import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import Header from '../components/Header.js';

import { signInToken } from './auth';

const theme = createTheme();


export default function Landing() {
  let navigate = useNavigate(); 
  const goToPageMain = () =>{ 
    let path = `main`; 
    navigate(path);
  }
  const signIn = signInToken;
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    axios.post(
      `https://n4ybe.dervish.rs/auth/jwt/login`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      )
      .then((response) => {
        // console.log(response);
        if (signIn(
          response.data.access_token,
          3600, // response.data.expiresIn, this should be, ideally, grabbed from server response
        )) {
        goToPageMain()
        } else {
          console.log("signIn failed");
        }
      })
      .catch((error) => console.log(error));
    };

  return (
    <div id='n4y-LoginScreen' className="n4y-placeholder">
        <div id='initialScreen' className="main active">
          <Header />
          <div className="login-separator">
            <div className="hr"></div>
            <p>Sign up or Login with</p>
          </div>
          <div id="btn1" className="btn Phone">
            <div className="media-icon"></div>
            <label>REGISTER</label>
          </div>
          <div className="login-separator">
          <div className="hr"></div>
            <p>or</p>
          </div>
          <div className="login-placeholder">
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="username"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            </Box>
          </div>
          <div className="login-separator">
            <div className="hr"></div>
            <p>Need an account? <a href="#">Register</a></p>
          </div>
        </div>
      </div>


    
  );
}
