import React from "react";
import { useNavigate } from "react-router-dom";

import appLogo from '../assets/login/gari-logo.png';
import userIcon from '../assets/user/user-login.png';
import pinIcon1 from '../assets/user/userAvatar1.png';
import pinIcon2 from '../assets/user/userAvatar2.png';
import pinIcon3 from '../assets/user/userAvatar3.png';
import userPin from '../assets/user/map-pin.svg';

import Map from '../components/mapComponent.jsx'; // The component created above
 
const Main = () => {

    let navigate = useNavigate(); 
    const goToPageLogin = () =>{ 
      let path = `../`; 
      navigate(path);
    }

    return (
    <div className='app-main'>
      <div className="app-header">
        <div className="branding"><img className="nfy-headerImg" src={appLogo} alt="Need4You" /></div>
        <div className="account"><img className="nfy-user-icon" src={userIcon} alt=""  /></div>
      </div>
      <div className="app-header-controls">
        <div className='interfaceContainer'>
          <button type="submit">NEED</button>
          <button type="submit">OFFER</button>
          <button type="submit"onClick={goToPageLogin}>LOG OUT</button>
        </div>
      </div>
      <div className="app-footer">
        <div className='interfaceContainer'>
          <button type="submit">Create Ticket</button>
        </div>
      </div>
      <div className='app-map-placeholder'>
      
        {/* Logged in userelements Start */}
        <div className='userPin' style={{zIndex:1500}}>
          <img className='basePin pinColorBase' src={userPin}  width="60" height="60" />
          <img className="userPinAvatar" src={userIcon} alt="" />
        </div>
        {/*Element radius circle <div className='UserLocation'></div>*/}
        {/* Logged in userelements End */}
        {/* Active pins Start <object className='basePin' data={userPin} width="40" height="40"> </object>*/}
        <div id='activePinID1' className='activePin' style={{marginLeft:-150, marginTop:-50 }}>
          <img className='basePin pinColorType1' src={userPin}  width="40" height="40" />
          <img className="userPinAvatar" src={pinIcon1} alt="" />
        </div>
        <div id='activePinID2' className='activePin' style={{marginLeft:90, marginTop:-90}}>
          <img className='basePin pinColorType2' src={userPin}  width="40" height="40" />
          <img className="userPinAvatar" src={pinIcon2} alt="" />
        </div>
        <div id='activePinID3' className='activePin' style={{marginLeft:-50, marginTop:90}}>
          <img className='basePin pinColorType3' src={userPin}  width="40" height="40" />
          <img className="userPinAvatar" src={pinIcon3} alt="" />
        </div>
        <Map />
        {/* Active pins End */}
      </div>
    </div>
    );
};
 
export default Main;