import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const Map = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const mapCenter = [latitude, longitude];
        const zoomLevel = 17;

        // Initialize map
        const map = L.map(mapRef.current).setView(mapCenter, zoomLevel);

        // Add OpenStreetMap tiles
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 20,
          attribution: 'Â© OpenStreetMap contributors',
        }).addTo(map);

      }, () => {
        alert("Geolocation is not supported by this browser.");
      });
    }
  }, []);

  return <div ref={mapRef} style={{ height: '100vh', width: '100%' }}></div>;
};

export default Map;