import React from "react";
 
const Register = () => {
    return (
        <div>
            <h1>
                GeeksforGeeks is a Computer Science portal
                for geeks.
            </h1>
        </div>
    );
};
 
export default Register;